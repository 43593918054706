.vehicle-importer {
    /*height: 250px;*/
    height: 100%;
}

.importer-header {
    text-align: center;
}

.importer-instructions {
    text-align: center;
}

.vehicle-checkbox-row p {
    margin-left: 24px;
    margin-bottom: 5px;
}

.vehicle-checkbox-row label {
    font-size: 20px;
    margin-bottom: 5px;
}

.vehicle-checkbox-col {
    margin-bottom: 15px;
}

.download-col {
    display: flex;
    flex-direction: column;
}

.download-col > div:nth-child(2) {
    flex: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.import-button {
    width: 350px !important;
}