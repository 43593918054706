.i2290-navbar {
    background-color: var(--primary-green);
}

.i2290-navbar ul {
    padding-left: 0;
}

.i2290-navbar i {
    font-size: 25px;
    color: white;
}

.logo-container {
    background-color: var(--tertiary-green);
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 78px;
    padding-top: 10px;
    padding-bottom: 10px;
    max-width: 150px !important;
}

.logo-container img {
    display: block;
    width: 70%;
}

.name-container {
    display: flex;
    flex-direction: column;
    color: var(--tertiary-green) !important;
}

.name-container .name-line {
    text-align: right;
}

.name-container .ein-line {
    text-align: right;
    font-weight: 800;
    font-size: 1.15rem;
    letter-spacing: -.075rem;
    line-height: 1rem;
}

.sub-nav {
    background-color: #0d1914;
    color: var(--primary-grey);
    font-size: .9rem;
    padding: 7px 0;
    border-bottom: 1px solid rgba(225, 225, 225, .1);
}

.signed-in-as {
    display: flex;
    justify-content: space-between;
}

.sub-nav a {
    color: #999 !important;
}

.sub-nav ul.breadcrumbs {
    margin: 0;
}

.sub-nav ul.breadcrumbs li {
    font-size: .9rem;
    display: inline-flex;
    margin: 0 20px;
}

@media only screen and (min-width: 768px) {
    .sub-nav .logout-link {
        margin-left: 30px;
    }
    .signed-in-as {
        display: block;
    }
}

.logo-container {
    max-width: 90px !important;
    padding-top: 0;
    padding-bottom: 0;
}

.logout-ctnr {
    background-color: var(--tertiary-green);
    color: var(--primary-yellow);
    width: 80px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.logout-ctnr > span {
    font-size: 0.8rem;
}