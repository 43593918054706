.sub-line-item {
    color: grey;
    font-size: 10px;
}

.total-line-item {
    font-weight: bold;
    border-top: 1px solid var(--soft-grey);
}

.summary-widget .row.line-item-row {
    font-size: 16px;
    margin-left: 0.25rem;
    margin-bottom: 0.6rem;
}

.disclaimer-text {
    font-size: 0.75em;
}

.total-ctnr {
    border-top: 3px solid var(--primary-black);
    display: flex;
    justify-content: space-between;
    padding: var(--gutter);
}

.cart-widget label {
    margin-bottom: 0;
}

.promo-code-field input {
    padding-top: 0;
    padding-bottom: 0;
    height: 40px;
}

.promo-code-field label {
    transform: translate(15px, 80%);
}

.prod-descrip {
    display: block;
    font-size: 1rem;
    font-family: sans-serif;
}

.prod-line-item-container {
    display: flex;
    align-items: center;
}

.prod-line-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.remove-promo-icon {
    margin-left: var(--negative-gutter);
    cursor: pointer;
}

.payment-modal img.cart-logo {
    height: 2em;
    width: 2em;
}

@media (max-width: 576px) {
    .total-col {
        margin-left: 60px;
    }
}

.submission-btn-ctnr {
    background-color: var(--primary-white) !important;
}