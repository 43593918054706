.disclaimer {
    border: 1px solid grey;
    border-radius: 3px;
    max-height: 400px;
    overflow: scroll;
    padding: 15px;
    margin-bottom: 15px;
}

.disclaimer h2 {
    font-size: 1rem;
}

.disclaimer > p:last-child {
    margin-bottom: 0;
}

.disclaimer-modal-body {
    max-width: 500px;
}