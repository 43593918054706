.vehicles-animator {
    /*display: flex;*/
    /*flex-direction: column;*/
    /*overflow: hidden;*/
    /*height: 500px;*/
    margin-bottom: 15px;
}

.vehicles-slide {
    /*min-height: 500px;*/
    /*max-height: 500px;*/
}

.nu-vehicles-screen {
    display: flex;
    flex-direction: column;
}