.profile-widget {
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    /*border: 2px inset grey;*/
    border-radius: 5px;
    color: white;
}

.profile-widget span {
    font-size: 20px;
}

.profile-widget p {
    margin-bottom: 15px;
}


.profile-widget i {
    font-size: 25px;
    margin-right: 15px;
}

.profile-widget button {
    margin-bottom: 15px;
}
