.new-user-header-cntr {
    /*text-align: center;*/
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.new-user-header-cntr h2 {
    color: #656565;
}

.new-user-link-area {
    width: 100%;
    text-align: center;
    margin-top: 15px;
}