.i2290-link {
  color: var(--primary-blue);
  cursor: pointer;
  user-select: none;
}

.i2290-link:focus {
  outline: 0;
}

.i2290-link:hover {
  color: #0056B3;
  text-decoration: underline;
}
