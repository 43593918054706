.credit-grid tr {
    line-height: 1.3;
}

.grid-text-color {
    color: var(--primary-black) !important;
}

.grid-text-error-color {
    color: var(--primary-red) !important;
}

.amount-cell {
    text-align: right !important;
}

.form-widget-vehicle-summ div.credit-grid {
    border-bottom: 1px solid var(--primary-green);
    font-size: 0.85em;
}

.credit-grid .k-grid-header col:nth-of-type(1){
    width: 20%;
}
.credit-grid .k-grid-table col:nth-of-type(1){
    width: 20%;
}
.credit-grid .k-grid-header col:nth-of-type(2){
    width: 20%;
}
.credit-grid .k-grid-table col:nth-of-type(2){
    width: 20%;
}
.credit-grid .k-grid-header col:nth-of-type(3){
    width: 15%;
}
.credit-grid .k-grid-table col:nth-of-type(3){
    width: 15%;
}
.credit-grid .k-grid-header col:nth-of-type(4){
    width: 15%;
}
.credit-grid .k-grid-table col:nth-of-type(4){
    width: 15%;
}
.credit-grid .k-grid-header col:nth-of-type(5){
    width: 15%;
}
.credit-grid .k-grid-table col:nth-of-type(5){
    width: 15%;
}
.credit-grid .k-grid-header col:nth-of-type(6){
    width: 10%;
}
.credit-grid .k-grid-table col:nth-of-type(6){
    width: 10%;
}
