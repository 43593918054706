.filer {
    max-width: 250px;
    border-radius: 28px;
    overflow: hidden;
    background-color: var(--secondary-green);
}

.filer fieldset {
    border-width: 3px;
    border-radius: 28px;
    overflow: hidden;
}

.filer select {
    color: var(--primary-white);
    font-weight: 500;
    font-size: 1rem;
    padding-top: 12px;
    padding-bottom: 12px;
}

.filer option {
    color: var(--primary-black);
    cursor: pointer;
    padding: 5px;
}