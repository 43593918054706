.StripeElement {
    display: block;
    margin: 10px 0 20px 0;
    /*max-width: 500px;*/
    min-height: 40px;
    padding: 10px 14px;
    font-size: 1em;
    font-family: 'Source Code Pro', monospace;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    border: 0;
    outline: 0;
    border-radius: 4px;
    background: white;
}

.payment-modal input::placeholder {
    color: #aab7c4;
}

.StripeElement--focus {
    box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
}

.StripeElement.IdealBankElement, .StripeElement.PaymentRequestButton {
    padding: 0;
}

.payment-modal-body {
    max-width: 610px;
}

.k-widget.k-window.k-dialog {
    max-height: 100vh;
    /* overflow-y: scroll;*/ /* AH: the dialog is getting big. is this needed for some browsers? */ 
}

.no-scroll > .k-widget.k-window.k-dialog {
    overflow-y: auto;
}

.payment-section-head {
    padding-left: 15px;
    margin-bottom: 15px;
    font-size: 20px;
}

.payment-modal img.bordered {
    border: 1px solid var(--quaternary-grey);
    width: 100%;
}

.payment-modal-head {
    margin-top: 30px;
    font-size: 30px;
}

.stripe-label {
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.54);
    margin-left: 13px;
}

.payment-modal .k-window-content {
    padding: var(--gutter);
}

.modal-open-body {
    overflow: hidden;
    height: 100%;
    position: fixed;
    width: 100%;
}
