td.command-cell {
    text-align: center !important;
    vertical-align: middle;
}

.command-cell .k-icon {
    font-size: 1.3rem;
    margin-right: 5px;
    margin-left: 5px;
    cursor: pointer;
}

.k-grid-toolbar {
    text-align: right;
}

td, td span, td .k-textbox {
    font-size: 12px;
}

.k-item, .k-list-optionlabel, .grid-font span {
    font-size: 12px !important;
}

.mail-buyer-info h1 {
    font-size: 20px;
}

.mail-buyer-info p {
    margin-bottom: 0;
}

.buyer-info-switch {
    flex-direction: row !important;
}
