.wizard-stepper {
  flex: auto;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-screen-hack {
  margin-right: -30px;
}

@media (max-width: 767px) {
  .wizard-stepper {
    /*flex-direction: column;*/
    font-size: 12px;
  }
  .wizard-step-number {
    height: 30px !important;
    width: 120px !important;
  }
  .wizard-break {
    display: none;
  }
 
  /*.wizard-break {*/
  /*  flex-direction: column;*/
  /*  margin-top: var(--gutter);*/
  /*  margin-bottom: 0;*/
  /*}*/
  /*.wizard-break > div {*/
  /*  margin-bottom: var(--gutter);*/
  /*}*/
  /*.form-screen-hack {*/
  /*  margin-right: var(--negative-gutter);*/
  /*}*/
}

@media (min-width: 1199px) {
  .wizard-step {
    width: 200px !important;
  }
}