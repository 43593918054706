/*#wrapper{*/
/*    height:100%;*/
/*}*/

/*.iconCol{*/
/*    background-color:black;*/
/*}*/

.imgContainer{
    width:250px;
    height:250px;
    /*margin:50% auto;*/
    /*margin-top:280px;*/
}

.formContainer{
    max-width:400px;
    /*margin:50% auto;*/
    /*margin-top:20%;*/
}

.imgContainer img{
    width:100%;
}

/*input[type=text] {*/
    /*background: transparent;*/
    /*border: none;*/
    /*border-radius:0px;*/
    /*border-bottom: 2px solid #c0c0c0;*/
/*}*/

/*input[type=password] {*/
    /*background: transparent;*/
    /*border: none;*/
    /*border-radius:0px;*/
    /*border-bottom: 2px solid #c0c0c0;*/
/*}*/

.info{
    color:#7C7C7C;

}

.label{
    color:#777777;
}

.info a{
    color:var(--primary-black);
}



.sendPinContainer .section{
    margin-top:25px;
    text-align:center;
}

.passwordLinkCtn{
    text-align:right;
}


/*.inputContainer{*/
/*    margin-top:20px;*/
/*}*/

#sendPin{
    background-color:black;
    color:white;
    width:250px;
    border-radius:5px;
    padding-top:10px;
    padding-bottom:10px;
}

#sendPin span{
    margin-left:10px;
    font-size:12pt;
}

/*.welcomeText{*/
/*    color:#BCBCBC;*/
/*    text-align:center;*/
/*    font-size:18px;*/
/*    margin-top:20px;*/
/*}*/

#signIn{
    padding:10px 60px;
    background-color:#333333;
    color:white;
    border-radius:4px;
}

#signUp{
    padding:10px 60px;
    background-color:#FFFFFF;
    border-radius:4px;
}

input[type='checkbox'] {
    -webkit-appearance:none;
    width:20px;
    height:20px;
    background:white;
    border-radius:5px;
    border:1px solid #CCC;
    margin-right:10px;
    position:absolute;
    bottom:2px;
}

input[type='checkbox']:checked {
    background: black;
}

.additionalHelpCtn{
   margin-top:30px;
    font-size:10pt;
}

#signUpBtnCtn{
    margin-top:50px;
}

#rememberMe{
    margin-left:30px;
}

#footerLinks{
    /*position:absolute;*/
    /*bottom:20px;*/
    text-align:center;
    /*right:0px;*/
    /*left:0px;*/
}

#footerLinks a{
    font-size:10pt;
    color:#2E0854;
    margin-left:3px;
    margin-right:3px;
}

@media only screen and (max-width: 768px){

    /*.iconCol{*/
    /*    display:none;*/
    /*}*/

    body{
        background-color:black;
    }

    .formCol{
        background-color:#EEEEEE;
    }

    #signIn{
        width:100%;
        padding:15px 5px;
        background-color:#333333;
        color:white;
        border-radius:4px;
        font-size:14px;
    }

    #signUp{
        width:100%;
        padding:15px 5px;
        background-color:#FFFFFF;
        border-radius:4px;
        font-size:14px;
    }

    /*.welcomeText{*/
    /*    font-size:14px;*/
    /*}*/

    .formContainer{
        /*padding-left:10%;*/
        /*padding-right:10%;*/
    }
}
