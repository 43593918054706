.current-year-filer {
    background-color: #323332;
    color: white;
    padding: 15px;
}

.current-year-filer h1 {
    font-size: 60px;
}

.current-year-filer .k-widget.k-window.k-dialog {
    overflow-y: unset !important;
}

.current-year-filer h2 {
    font-size: 40px;
    font-weight: 300;
}

.current-year-filer .section-1, .current-year-filer .player-wrapper {
    text-align: center;
}

.current-year-filer .section-2 {
    padding-top: 15px;
}