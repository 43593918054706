.contracted-faq-head {
    color: var(--primary-green);
}


.expanded-faq-head {
    color: var(--primary-yellow);
}

.faq-head-text:hover {
    color: var(--primary-yellow);
}