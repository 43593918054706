.largeSize {
    font-weight: 700;
    font-size: x-large;
}

.xlargeSize {
    font-size: xx-large;
}

.center {
    text-align: center !important;
}

.heading {
    margin: 10px;
    width: 730px;
    padding: 10px;
    text-align: center;
}

.headingText {
    color: white;
    margin: 0;
}

.warningText {
    color: red;
    font-size: x-large;
}

.divStyle {
    height: 2px;
    margin: "15px 0";
}

.paragraph {
    text-align: left;
    font-size: medium;
}

.emphasis {
    text-decoration: underline;
    font-size: 500;
}

.paragraph {
    font-size:medium;
    text-align: left;
}