.receipt-modal-body {
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    flex-direction: column;
    height: 100px;
}

.receipt-modal-body .i2290-inline-error {
    margin-top: 5px;
}