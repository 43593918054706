.page-content-wrapper {
    background-color: var(--primary-bg-color);
    min-height: 100vh;
}

.site-background, body {
    background-color: var(--primary-bg-color);
}

.site-wrapper {
    min-height: 100vh;
}

.navbar-collapse {
    justify-content: flex-end;
}

