.i2290-nav-menu {
    list-style: none;
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    justify-content: right;
    margin-top: 15px;
    width: 100%;
    height: 100%;
}

.filer-link {
    flex: 1;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.i2290-nav-menu li {
    margin-left: 0;
    margin-bottom: var(--gutter);
    display: flex;
    align-items: center;
}

.i2290-nav-menu .i2290-link {
    color: var(--primary-white);
    padding: 10px 30px 11px;
    font-size: 1rem;
    font-weight: 600;
    opacity: 1;
    text-decoration: none;
}

.i2290-nav-menu .i2290-link.file-now {
    background: var(--tertiary-green);
    border-radius: 55px;
    color: rgba(255, 201, 38, 1);
    margin-right: var(--gutter);
}

.i2290-nav-menu:hover .i2290-link {
    opacity: .85;
}

.i2290-nav-menu .i2290-link:hover {
    opacity: 1;
}

.i2290-nav-menu .i2290-link.active {
    color: #ffc737;
    opacity: 1;
}

@media only screen and (min-width: 768px) {
    .i2290-nav-menu {
        margin-top: 0;
        flex-direction: row;
    }
    .i2290-nav-menu li {
        margin-left: 5px;
        margin-bottom: 0;
    }
    .i2290-nav-menu .i2290-link.file-now {
        margin-right: 0;
        margin-bottom: 0;
    }
}