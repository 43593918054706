.support-center section {
    margin-bottom: 15px !important;
}

.support-center .support-forms h2, .faq-section h2, .support-center .irs-links h2, .support-center .downloads-section h2 {
    text-align: center;
    margin-bottom: 30px;
}

.section-spacer {
    margin-bottom: 30px;
}

.faq-section .summary-widget {
    border: 1px solid var(--primary-grey);
    margin-bottom: 5px;
}
