.new-user-frame {
  display: flex;
}

/*.new-user-sidebar {*/
/*  background-color: black;*/
/*  width: 500px;*/
/*  min-width: 500px;*/
/*  max-width: 500px;*/
/*  margin-left: 0;*/
/*  -webkit-transition: margin 0.25s ease-out;*/
/*  -moz-transition: margin 0.25s ease-out;*/
/*  -o-transition: margin 0.25s ease-out;*/
/*  transition: margin 0.25s ease-out;*/
/*}*/

/*@media (max-width: 1032px) {*/
/*  .new-user-sidebar {*/
/*    margin-left: -500px;*/
/*  }*/
/*}*/

.new-user-content-area {
  /*flex: auto;*/
  display: flex;
  flex-direction: column;
  /*padding: 15px;*/
}

.new-user-crumb-area {
  height: 150px;
  /*padding: 15px;*/
  display: flex;
  justify-content: center;
  align-items: center;
}

.new-user-screen-area {
  flex: auto;
  display: flex;
}

.new-user-screen-area > div {
  flex: auto;
}

.new-user-sidebar-btn-cntr {
  margin-top: 30px;
  padding-right: 30px;
  padding-left: 30px;
  margin-right: auto;
  margin-left: auto;
}

.new-user-sidebar-btn {
  border-color: white !important;
  border-width: 1px !important;
  margin-bottom: 30px;
}

.new-user-ctrl-area {
  width: 100%;
}

.new-user-inner-content {
  max-width: 800px;
  margin: auto;
}