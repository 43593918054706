.i2290-btn {
    text-transform: none !important;
    box-shadow: none !important;
    border-radius: 3px !important;
    border: 1px solid var(--primary-blue) !important;
    font-weight: 500 !important;
    padding: 0 var(--gutter) !important;
    height: 40px;
    font-size: 14px;
    /*width: 110px;*/
}

.i2290-btn:disabled {
    background-color: rgba(0,0,0,0.12) !important;
    box-shadow: none !important;
    color: rgba(0,0,0,0.38) !important;
    opacity: 0.5;
    border: none !important;
}

.i2290-btn-primary-old, .i2290-link-primary-old {
    background-color: var(--primary-white) !important;
    color: var(--primary-blue) !important;
    text-transform: none !important;
    box-shadow: none !important;
    border-radius: 3px !important;
    border: 1px solid var(--primary-blue) !important;
    font-weight: 400 !important;
    padding: 0 var(--gutter) !important;
    height: 30px;
    font-size: 14px;
}

.i2290-link-primary:visited, .i2290-link-primary:active {
    color: var(--primary-blue);
}

.i2290-btn-primary {
    background-color: var(--primary-blue) !important;
    color: var(--primary-white) !important;
}

.i2290-btn-secondary {
    background-color: var(--primary-white) !important;
    color: var(--primary-blue) !important;
}

.i2290-btn-primary:hover, .i2290-btn-secondary:hover{
    background-color: var(--primary-white) !important;
    color: var(--primary-blue) !important;
}

.i2290-link-primary {
    background: none !important;
    margin-left: 10px;
    border: 1px solid rgba(255, 201, 38, .15) !important;
    padding: 8px 25px;
    min-width: 105px;
    font-size: 14px;
    color:rgba(255, 201, 38, 1) !important;
    font-weight: 400;
}

.i2290-link-primary:hover{
    background: #fbc922 !important;
    color:#2a2a2a !important;
    text-decoration: none !important;
}

.next-button span.k-icon.k-i-arrow-chevron-right {
    position: absolute;
    right: 12px;
}

.next-button .next {
    width:100%;
    margin-left: -25px;
}

.grid-screen-btn {
    max-width: 150px;
}

.summary-header button.details-button {
    padding-right: 30px !important;
    color: var(--quinary-grey) !important;
    border: 1px solid var(--quinary-grey) !important;
}

.summary-header button.details-button:hover {
    background-color: unset !important;
}

.details-button span.k-icon.k-i-arrow-chevron-down {
    position: absolute;
    right: 5px;
}

.details-button span.k-icon.k-i-arrow-chevron-up {
    position: absolute;
    right: 5px;
}
