.form-widget-body {
    padding: 0;
    border-top: 1px solid #f0f0f0;
}
 
.form-widget-body .k-widget {
  border-left-width: 0;
  border-right-width: 0;
}

.form-widget-vehicle-summ{
    padding: 8px 0px 8px 0px;
    margin-top: 10px !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    background-color: var(--soft-green);
    border-bottom: 1px solid var(--primary-green);
    font-weight: 500;
    font-size: 1.2em;
}

.subheader-text {    
   font-size: 0.80em !important;
}

.form-widget-body .text-lg {
    font-weight: 500;
}

.form-widget-body .k-grid-header {
    display: none;
}

.form-widget-body td {
    padding: 5px 15px !important;
}

.totals-border {
    border-bottom: 2px solid black;
}