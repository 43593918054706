.support-form-screen .underline-head {
    margin-bottom: 30px;
}

.support-form-btn-section {
    padding-top: 15px;
    padding-bottom: 15px;
}

.support-form-btn-section button {
    margin-bottom: 15px;
}

@media (min-width: 576px) {
    .support-form-btn-section button {
        max-width: 280px;
    }
}

.inline-sub {
    font-size: 25px;
    margin-bottom: 30px;
}

.inline-sub strong {
    font-size: 32px;
}

.support-page-header {
    font-size: 40px;
    font-weight: 500;
}
