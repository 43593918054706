.footer {
    background-color: #323332;
    padding: 15px;
}

.footer-container {
    padding: 0 15px 15px;
}

.footer-img-container {
    border: 1px solid red;
}

.footer img {
    height: 75px;
    width: auto;
    margin-right: 15px;
    margin-bottom: 15px;
}

.footer-link-section {
    color: white;
}

.footer-link {
    color: #81c400;
}

.footer-link:hover {
    color: #ffcc00;
}

.footer p:last-child {
    margin-bottom: 0;
}
