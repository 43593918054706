.slide-down-animator {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin-bottom: 15px;
}

/*.slide-down-slide {*/
/*    min-height: 500px;*/
/*    max-height: 500px;*/
/*}*/

