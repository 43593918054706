.validation-summary {
    /*padding-left: ;*/
    /*margin-bottom: var(--negative-gutter);*/
    /*margin-top: 15px;*/
    /*padding-bottom: var(--gutter);*/
    /*padding-top: var(--gutter);*/
    padding-left: 0;
    width: 100%;
    margin-bottom: 0;
}

.validation-summary li {
    /*color: red;*/
    list-style: none;
    padding: var(--gutter);
    border-bottom: 1px solid #f5c6cb;
}

.validation-summary > li:last-child {
    border-bottom: none;
}