.wizard-step {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.wizard-step-number {
  background-color: #FDC926;
  color:var(--primary-black);
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: left;
  align-items: center;
  margin-bottom: 10px;
  font-size: 0.9em;
  font-weight: 500;
  padding: 2em 1em;
  border-radius: var(--border-radius);
  cursor: pointer;
}

.icon-green {
  color: var(--primary-green);
}

.icon-red {
    color: var(--primary-red);
}

.icon-orange {
  color: var(--primary-orange);
}

.wizard-step.wiz-active .wizard-step-number, .form-screen-wiz-step:hover .wizard-step-number, .wizard-step .wizard-step-number:hover {
  color: var(--primary-bg-color) !important;
  background-color: var(--primary-white) !important;
  
}

/* Past screen button style
.wizard-step.wiz-past .wizard-step-number {
 color: var(--primary-black);
  background-color: var(--primary-yellow);
  border-color: var(--primary-yellow);
}
*/

.wizard-step.wiz-past .wizard-step-title {
  color: var(--quinary-grey);
}

.wizard-step-title {
  color: var(--tertiary-grey);
}

.wizard-step.wiz-active .wizard-step-title {
  color: var(--primary-white);
}

.form-screen-wiz-title {
  color: var(--primary-white) !important;
}

.form-screen-wiz-step:hover .wizard-step-title {
  text-decoration: underline;
}

.review-step:hover {
    cursor: default !important;
}
.review-step:hover .wizard-step-title {
  text-decoration: none;
}