.submission-widget h1 {
    font-size: 20px;
}

.submission-widget {
    background-color: var(--secondary-grey);
    margin-top: -15px;
    /*padding-bottom: 15px;*/
}

.submission-button {
    max-width: 350px;
    font-size: 16px !important;
}
