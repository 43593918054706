.summary-widget {
    /*-webkit-box-shadow: 0 0 10px 2px rgba(0,0,0,0.07);*/
    /*-moz-box-shadow: 0 0 10px 2px rgba(0,0,0,0.07);*/
    /*box-shadow: 0 0 10px 2px rgba(0,0,0,0.07);*/
    /*margin-bottom: 15px;*/
}

.widget {
    -webkit-box-shadow: 0 0 10px 2px rgba(0,0,0,0.07);
    -moz-box-shadow: 0 0 10px 2px rgba(0,0,0,0.07);
    box-shadow: 0 0 10px 2px rgba(0,0,0,0.07);
    margin-bottom: 15px;
    padding: 15px;
    background-color: #fff !important;
}

.widget-background {
    background-color: white;
}

.summary-widget .panel {
    margin-bottom: 0;

    cart-widget {
        row {
            font-size: i6px;
        }
    }
}

.summary-header {
    display: flex;
    padding: 15px;
    background-color: var(--primary-white);
    /*border-bottom: 1px solid black;*/
}

.summary-header.no-bottom-padding {
    padding-bottom: 0px;
    padding-right: 0px;
}

.summary-header.low-top-padding {
    padding-top: 10px;
}

/*.summary-header-int {*/
/*    height: 35px;*/
/*}*/

.summary-header > div {
    flex: auto;
}

.summary-header .i2290-switch {
    height: 35px;
}

.summary-header-dropdown {
    margin-bottom: 0 !important;
}

/*.widget-body {*/
/*    padding: 15px;*/
/*}*/

.summary-header h2 {
    font-size: 20px;
    margin-bottom: 0;
    display: inline;
    margin-right: 15px;
}

.summary-header p {
    margin-bottom: 0;
}

.static-summ-head {
    padding: 0 var(--gutter);
    background-color: #fff !important;
    margin-bottom: 15px;
}

.order-id-label {
    color: var(--primary-black);
    font-size: 1.2em;   
}

.order-id-label strong {
    
    margin-top: -10px;
    display: block;
}
