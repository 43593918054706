.form-report h1, h2, h3 {
    margin-bottom: 0;
}

.form-report h1 {
    font-size: 2.5rem;
}

.form-report h2 {
    font-size: 2rem;
}

.form-report section {
    padding: 15px;
}

.form-report .order-details .form-report-header h2 {
    flex: auto;
}

.form-report-header {
    border-bottom: 2px solid black;
    padding-bottom: 15px;
    margin-bottom: 15px;
}

.inline-head {
    font-weight: bold;
}

.form-report-subhead {
    border-bottom: 1px solid grey;
    margin-bottom: 15px;
}

.form-report-third-party {
    display: flex;
    flex-direction: column;
}

.form-report-subhead p {
    margin-bottom: 15px;
}

.vehicle-list {
    list-style: none;
    padding-left: 15px;
    padding-right: 15px;
}

.vehicle-list li {
    border-bottom: 1px solid grey;
    padding-top: 15px;
    padding-bottom: 15px;
}

.form-report .totals .row {
    padding-top: 15px;
    padding-bottom: 15px;
}

.line-item {
    border-top: 1px solid grey;
    width: 100%;
}

/*@media print {*/
/*    .form-report {*/
/*        font-size: 10pt;*/
/*    }*/
/*    .col-print-1 {width:8%;  float:left;}*/
/*    .col-print-2 {width:16%; float:left;}*/
/*    .col-print-3 {width:25%; float:left;}*/
/*    .col-print-4 {width:33%; float:left;}*/
/*    .col-print-5 {width:42%; float:left;}*/
/*    .col-print-6 {width:50%; float:left; color: blue !important;}*/
/*    .col-print-7 {width:58%; float:left;}*/
/*    .col-print-8 {width:66%; float:left;}*/
/*    .col-print-9 {width:75%; float:left;}*/
/*    .col-print-10{width:83%; float:left;}*/
/*    .col-print-11{width:92%; float:left;}*/
/*    .col-print-12{width:100%; float:left;}*/
/*}*/

.irs-info {
    display: flex;
    flex-direction: column;
}

.back-to-orders {
    width: 150px !important;
    margin-right: 15px;
}

@media print {
    .footer {
        display: none;
    }
    .schedule-1-link {
        color: #0D1914;
    }
    .navbar, .i2290-navbar {
        display: none;
    }
    @page {
        margin-top: 50px;
        margin-bottom: 30px;
        size: auto;
    }
    .print-button-ctnr {
        display: none;
    }
}
