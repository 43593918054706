.weight-increase-button {
    max-width: 300px;
}

.weight-increase-error {
    color: red;
    padding: 15px;
}

.vin-value {
    font-size: 2rem;
}

@media (max-width: 575px) {
    .vin-value {
        font-size: unset;
    }
}