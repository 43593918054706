.payment-method-widget-body {
    padding: 15px;
}

.payment-method-widget-header h2 {
    font-size: 20px;
    margin-bottom: 0;
    display: inline;
    margin-right: 15px;
}

.payment-widget-chevron {
    font-size: 20px;
}
