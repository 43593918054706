.ack-copied-vehicles-modal-body {
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    flex-direction: column;
    height: 100px;
}

.ack-copied {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    /*margin: 15px var(--negative-gutter) var(--negative-gutter);*/
}

.ack-copied p {
    margin-bottom: 0;
}