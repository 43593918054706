.vin-corrections {
    list-style: none;
    padding-left: 0;
}

.vin-correction button {
    margin-bottom: 30px;
}

.add-vin-btn {
    max-width: 250px;
}


@media (min-width: 992px) {
    .vin-correction button {
        margin-bottom: 15px;
    }
}
