.form-summary-panel {
    /*padding-left: 15px;*/
    /*padding-right: 15px;*/
    border-top: 1px solid var(--primary-grey);
    /*height: 50px;*/
}

.form-summary-panel:first-child {
    border-top: none;
}

.form-summary-panel button {
    margin-right: var(--gutter);
}

.form-placeholder {
    min-height: 60px;
    height: 100%;
}

.form-placeholder button {
    margin-bottom: -15px;
    height: 30px !important;
    font-size: 10px;
    width: 80px !important;
}

.form-summary-panel section {
    height: 100%;
}

.form-summary-panel .section-2 {
    padding: 15px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: #323332;
    color: white;
}

.form-summary-panel .section-2 span {
    margin-right: auto;
}

.form-summary-panel section.section-1 {
    background-color: #CCCCCC;
    color: var(--primary-black);
}

.form-summary-section {
    padding: 15px;
    display: flex;
    font-size: 12px;
    /*justify-content: flex-start !important;*/
}

.form-summary-section > div {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.form-info {
    padding-left: var(--double-gutter) !important;
}

@media (max-width: 575px) {
    .form-info {
        padding-left: var(--gutter) !important;
    }
}

.status-container {
    min-height: 60px;
}