/* VARS ----------------------- */

:root {
  --gutter: 15px;
  --half-gutter: calc(var(--gutter) / 2);
  --double-gutter: calc(var(--gutter) * 2);
  --negative-gutter: calc(var(--gutter) * -1);
  --primary-bg-color: #0d1914;
  --primary-yellow: #FCC900;
  --soft-green: #EFF4E7;
  --primary-green: #679417;
  --primary-red: #FF0000;
  --primary-orange: #FF7F50;
  --secondary-green: #7CA633;
  --tertiary-green: #304906;
  --quaternary-green: #527D08;
  --primary-white: #FFFFFF;
  --secondary-white: #f8f9fa;
  --primary-black: #0D1914;
  --primary-blue: #007bff;
  --primary-grey: rgba(225, 225, 225, .8);
  --secondary-grey: #e9e9e9;
  --tertiary-grey: #474747;
  --quaternary-grey: #27332e;
  --quinary-grey: grey;
  --soft-grey: #f0f0f0;
  --border-radius: 3px;
  --bs-sm: 576px;
  --bs-md: 768px;
  --bs-lg: 992px;
  --bs-xl: 1200px;
}

/* BASE ----------------------- */

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  color: var(--primary-black) !important;
}

html, body {
  overflow-x:hidden;
}

html, body, #root, .app, .app > div, .site-wrapper, .page-content-wrapper {
  width: 100%;
}

p {
  font-size: .9em;
}

h1.header-size {
  font-size: 1.5rem
}

h2.subheader-size {
  font-size: 1.2rem;
}

/* SPACERS ----------------------- */

.m-gutter {
  margin: var(--gutter);
}

.mx-gutter {
  margin-right: var(--gutter);
  margin-left: var(--gutter);
}

.mx-negative-gutter {
  margin-right: var(--negative-gutter);
  margin-left: var(--negative-gutter);
}

.my-gutter {
  margin-top: var(--gutter);
  margin-bottom: var(--gutter);
}

.mt-gutter {
  margin-top: var(--gutter);
}

.mr-gutter {
  margin-right: var(--gutter);
}

.mb-gutter {
  margin-bottom: var(--gutter) !important;
}

.ml-gutter {
  margin-left: var(--gutter);
}

.p-gutter {
  padding: var(--gutter);
}

.px-gutter {
  padding-left: var(--gutter);
  padding-right: var(--gutter);
}

.py-gutter {
  padding-top: var(--gutter);
  padding-bottom: var(--gutter);
}

.pt-gutter {
  padding-top: var(--gutter);
}

.pr-gutter {
  padding-right: var(--gutter);
}

.pb-gutter {
  padding-bottom: var(--gutter);
}

.pl-gutter {
  padding-left: var(--gutter);
}

.p-half-gutter {
  padding: var(--half-gutter);
}

.px-half-gutter {
  padding-left: var(--half-gutter);
  padding-right: var(--half-gutter);
}

.py-half-gutter {
  padding-top: var(--half-gutter);
  padding-bottom: var(--half-gutter);
}

.pt-half-gutter {
  padding-top: var(--half-gutter);
}

.pr-half-gutter {
  padding-right: var(--half-gutter);
}

.pb-half-gutter {
  padding-bottom: var(--half-gutter);
}

.pl-half-gutter {
  padding-left: var(--half-gutter);
}

.no-margin, div.no-margin {
  margin-bottom: 0 !important;
}

/* CLASSES ----------------------- */
.brand-header {
  background-color: var(--quaternary-green) !important;
  color: var(--primary-white);
}

.soft-border-bottom {
  border-bottom: 1px solid var(--soft-grey);
}

.hard-border-bottom {
  border-bottom: 1px solid var(--primary-grey);
}

.transparent {
  opacity: 0.9;
}

.widget-head-text {
  font-size: 25px !important;
}

.widget-body {
  background-color: white;
  padding: 0px var(--gutter);
  font-size: medium;
}

.secondary-white-bg {
  background-color: var(--secondary-white);
}

.summary-body {
  background-color: #f8f9fa !important;
  padding: 15px 15px 0;
  border-top: 1px solid #f0f0f0;
}

.i2290-inline-error {
  color: red;
}

.i2290-inline-success {
  color: green;
}

.i2290-busy-container {
  min-height: 25px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
}

.bg-header {
  color: var(--primary-grey);
  font-size: 1.5rem;
  margin-bottom: var(--gutter);
}

.drop-shadow {
  -webkit-box-shadow: 0 0 10px 2px rgba(0,0,0,0.07);
  -moz-box-shadow: 0 0 10px 2px rgba(0,0,0,0.07);
  box-shadow: 0 0 10px 2px rgba(0,0,0,0.07);
}

.dashboard-main-bg {
  background-color: #323332;
}

/* HELPERS ----------------------- */
.no-break {
  white-space: nowrap;
}

/* must be used with no-break */
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

.rad-corners {
  border-radius: var(--border-radius);
  overflow: hidden !important;
}

.rad-corners-loose {
  border-radius: var(--border-radius);
}

.clean-gutter-right,
.clean-gutter-sm-right,
.clean-gutter-md-right,
.clean-gutter-lg-right,
.clean-gutter-xl-right {
  padding-right: var(--half-gutter) !important;
}

.clean-gutter-left,
.clean-gutter-sm-left,
.clean-gutter-md-left,
.clean-gutter-lg-left,
.clean-gutter-xl-left {
  padding-left: var(--half-gutter) !important;
}

/* MEDIA ----------------------- */
@media (max-width: 575px) {
  .clean-gutter-sm-right {
    padding-right: var(--gutter) !important;
  }
  .clean-gutter-sm-left {
    padding-left: var(--gutter) !important;
  }
}

@media (max-width: 767px) {
  .clean-gutter-md-right {
    padding-right: var(--gutter) !important;
  }
  .clean-gutter-md-left {
    padding-left: var(--gutter) !important;
  }
}


@media (max-width: 991px) {
  .clean-gutter-lg-right {
    padding-right: var(--gutter) !important;
  }
  .clean-gutter-lg-left {
    padding-left: var(--gutter) !important;
  }
}

@media (min-width: 1199px) {
  .gutter-xl-right {
    padding-right: var(--gutter) !important;
  }
}

@media (max-width: 1199px) {
  .clean-gutter-xl-right {
    padding-right: var(--gutter) !important;
  }
  .clean-gutter-xl-left {
    padding-left: var(--gutter) !important;
  }
}

/* SCREEN OVERRIDES ----------------------- */
.form-screen-right .cart-widget-header,
.form-screen-right .cart-widget-body,
.form-screen-right .payment-method-widget-header,
.form-screen-right .payment-method-widget-body
/*.form-screen-right .submission-widget*/
{
  opacity: 0.9
}

.form-screen-right .submission-widget {
  background-color: rgba(255, 255, 255, 0.9)
}

/* KENDO ----------------------- */
.k-window {
  border-radius: var(--border-radius) !important;
}

.k-window-titlebar {
  white-space: unset !important;
}