.support-footer {
    padding: 15px;
    /*border: 1px solid #dedede;*/
    /*border-radius: 4px;*/
}

.support-footer img {
    max-width: 250px;
    margin-top: -43px;
    margin-bottom: -15px;
}
