.link-widget {
    padding: 30px 15px;
    background-color: white;
    /*height: 200px;*/
    min-width: 165px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: auto auto 15px;
    height: 250px;
    border-radius: var(--border-radius);
}

.link-widget p {
    width: 100%
}

.link-widget:hover {
    background-color: var(--primary-yellow);
}

/*.link-widget:hover .link-widget-link {*/
/*    color: white;*/
/*}*/

.link-widget-link {
    color: var(--primary-green);
    font-size: 30px;
}

.link-widget p, .link-widget span {
    text-align: center;
}

.link-widget h2 {
    font-size: 25px;
    color: #8A8A8A;
    text-align: center;
}

/*.link-widget-icon {*/
/*    height: 50px;*/
/*    width: 50px;*/
/*}*/

.link-widget-icon {
    font-size: 30px !important;
}
