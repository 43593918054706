.support-hero-section {
    padding-top: 15px;
    padding-right: 15px;
    padding-left: 15px;
    background-color: white;
    margin-bottom: 15px;
    border-radius: var(--border-radius);
}

.support-hero-section button {
    width: 200px;
}

.support-hero-section h1 {
    font-size: 60px;
    text-transform: uppercase;
}

.support-hero-section p {
    font-size: 25px;
}
