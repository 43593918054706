.i2290-switch-label {
  font-weight: bold !important;
  font-size: 12px;
}

.i2290-switch {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
}
