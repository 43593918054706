/*!
 * Start Bootstrap - Simple Sidebar (https://startbootstrap.com/template-overviews/simple-sidebar)
 * Copyright 2013-2019 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap-simple-sidebar/blob/master/LICENSE)
 */

body {
  overflow-x: hidden;
}

/*#page-content-wrapper {*/
/*  background-color: #ededed;*/
/*}*/

#sidebar-wrapper {
  min-height: 100vh;
  margin-left: -80px;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 20px;
  font-size: 1.2rem;
}

#sidebar-wrapper .list-group-item {
  text-align: center;
}


#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}

@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }

  /*#page-content-wrapper {*/
  /*  min-width: 0;*/
  /*  width: 100%;*/
  /*}*/

  #wrapper.toggled #sidebar-wrapper {
    margin-left: -3rem;
  }
}

/********navbar styles*********/

#search-form {
  position: relative;
  top: 4px;
  right: 30px;
}

.highlight-white {
  color: white !important;
}
.fa-bell {
  margin-right: 15px;
  transform: scale(0.7);
}

/****dropdown****/
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown > input[type="checkbox"] {
  position: absolute;
  left: -100vw;
}

.dropdown > label,
.dropdown > a[role="button"] {
  display: inline-block;
  padding: 6px 25px;
  color: #fff;
  line-height: 1.5em;
  text-decoration: none;
  border: 1px solid #8c8c8c;
  cursor: pointer;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.dropdown > label:hover,
.dropdown > a[role="button"]:hover,
.dropdown > a[role="button"]:focus {
  border-color: #333;
}

.dropdown > label:after,
.dropdown > a[role="button"]:after {
  content: "\f0d7";
  font-family: FontAwesome;
  display: inline-block;
  margin-left: 6px;
}

.dropdown > ul {
  position: absolute;
  z-index: 999;
  display: block;
  left: -100vw;
  top: calc(1.5em + 14px);
  border: 1px solid #8c8c8c;
  background: #fff;
  padding: 6px 0;
  margin: 0;
  list-style: none;
  width: 100%;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
}

.dropdown > ul a {
  display: block;
  padding: 6px 15px;
  text-decoration: none;
  color: #333;
}

.dropdown > ul a:hover,
.dropdown > ul a:focus {
  background: #ececec;
}

.dropdown > input[type="checkbox"]:checked ~ ul,
.dropdown > ul:target {
  left: 0;
}

.dropdown > [type="checkbox"]:checked + label:after,
.dropdown > ul:target ~ a:after {
  content: "\f0d8";
}

.dropdown a.close {
  display: none;
}

.dropdown > ul:target ~ a.close {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  text-indent: -100vw;
  z-index: 1000;
}

/***Logo container and logo img****/

#logoContainer {
  background-color: black;
}

/**navbar**/

.main-nav {
  background-color: black;
  height: 65px !important;
}

/****content area****/

#content-area {
  padding-top: 15px;
}

.custom-select {
  background: transparent;
  border: none;
  border-radius: 0px;
  border-bottom: 2px solid #c0c0c0;
}

.panel {
  padding: var(--gutter);
  margin-bottom: 15px;
}

.custom-panel-row {
  border-bottom: 1px solid #f0f0f0;
  padding-top: 20px;
  padding-bottom: 20px;
}

.panel-header-section {
  font-weight: bold;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.panel-header-section-borderless {
  font-weight: bold;
  padding-bottom: 15px;
}
/****content area menu****/

.content-menu-item {
  cursor: pointer;
  padding: 8px 10px;
  border-top: 1px solid #f0f0f0;
}

.item-active {
  background-color: black;
  color: white;
}

/***** toggle switch css*****/
input.apple-switch {
  position: relative;
  -webkit-appearance: none;
  outline: none;
  width: 50px;
  height: 30px;
  background-color: #fff;
  border: 1px solid #d9dadc;
  border-radius: 50px;
  box-shadow: inset -20px 0 0 0 #fff;
}

input.apple-switch:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  background: transparent;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.2);
}

input.apple-switch:checked {
  box-shadow: inset 20px 0 0 0 #4ed164;
  border-color: #4ed164;
}

input.apple-switch:checked:after {
  left: 20px;
  box-shadow: -2px 4px 3px rgba(0, 0, 0, 0.05);
}

.driving-under-authority h1 {
  font-size: 20px;
  color: #676665;
}

.getting-authority-ctnr {
  display: flex;
  align-items: center;
}

.getting-authority-ctnr h1 {
  white-space: nowrap !important;
}

.material-label-override {
  margin-bottom: 0 !important;
  white-space: nowrap !important;
}

@media only screen and (max-width: 1509px) {
  div.getting-authority-ctnr {
    justify-content: center !important;
  }
}

@media only screen and (max-width: 835px) {
  div.getting-authority-ctnr {
    justify-content: center !important;
  }
  div.getting-authority-ctnr > div.i2290-radio-group {
    flex-direction: column !important;
  }
  .getting-authority-ctnr h1 {
    white-space: normal !important;
  }
}

.eftps-selected, .money-order-selected {
  margin-top: 10px;
}

.eftps-selected > label {
  width: 100% !important;
}

.account-form-col-head {
  border-bottom: 1px solid #f0f0f0;
  margin-right: -30px;
  margin-left: -30px;
  padding-left: 30px;
  padding-bottom: 15px;
  font-weight: bold;
  margin-bottom: 15px;
}

.account-form-fluid-head {
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 15px;
  margin-left: -30px !important;
  margin-right: -30px !important;
  font-weight: bold;
  padding-left: 30px;
  padding-right: 15px;
  width: calc(100% + 60px);
  margin-bottom: 15px;
}

.driver-authority {
  padding-bottom: 0;
}

.notification {
  padding-top: 0;
  padding-bottom: 0;
}
