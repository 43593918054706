.edit-vehicles-screen .tax-grid,
.edit-credits-screen .tax-grid {
  border-radius: var(--border-radius);
  overflow: hidden;
  /*max-width: 600px;*/
}

.edit-credits-screen .credits-grid .k-hierarchy-cell {
  padding: 0 !important;
}

.vehicle-grid tr {
  line-height: 1.3;
}

.amount-cell {
  text-align: right !important;
}

.form-widget-vehicle-summ div.vehicle-grid {
  border-bottom: 1px solid var(--primary-green);
  font-size: 1.2em;
}

.vehicle-grid .k-grid-header col:nth-of-type(1) {
  width: 20%;
}
.vehicle-grid .k-grid-table col:nth-of-type(1) {
  width: 20%;
}
.vehicle-grid .k-grid-header col:nth-of-type(2) {
  width: 25%;
}
.vehicle-grid .k-grid-table col:nth-of-type(2) {
  width: 25%;
}
.vehicle-grid .k-grid-header col:nth-of-type(3) {
  width: 20%;
}
.vehicle-grid .k-grid-table col:nth-of-type(3) {
  width: 20%;
}
.vehicle-grid .k-grid-header col:nth-of-type(4) {
  width: 20%;
  padding: 0px;
  text-align: right;
}
.vehicle-grid .k-grid-table col:nth-of-type(4) {
  width: 20%;
}
.vehicle-grid .k-grid-header col:nth-of-type(5) {
  width: 10%;
}
.vehicle-grid .k-grid-table col:nth-of-type(5) {
  width: 10%;
}

.edit-vehicles-screen .vehicle-grid td,
.edit-vehicles-screen .vehicle-grid th {
  font-size: 13px;
}

.vehicle-editor {
  padding: var(--gutter);
}

.edit-vehicles-screen .vehicle-editor {
  background-color: var(--secondary-white);
}

.edit-vehicles-screen .vehicle-grid th,
.nu-vehicles-screen .vehicle-grid th,
.edit-credits-screen .credits-grid th {
  padding-top: 5px;
  padding-bottom: 5px;
}

.edit-vehicles-screen .vehicle-grid thead,
.edit-credits-screen .credits-grid thead {
  background-color: var(--secondary-grey);
}

.edit-vehicles-screen .tax-grid .k-pager-wrap,
.edit-credits-screen .tax-grid .k-pager-wrap {
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}

.tax-grid td,
.tax-grid th {
  padding-left: var(--gutter) !important;
  padding-right: var(--gutter) !important;
}

.vehicle-grid-width {
  max-width: 965px !important;
  width: 100%;
  margin: auto;
}

.vehicle-editor-checks {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
}

.vehicle-editor-checks-header {
  font-size: 20px;
  margin-top: -27px;
  margin-left: -5px;
  padding-left: 15px;
  background-color: var(--primary-white);
  width: 212px;
}

.edit-vehicles-screen .vehicle-editor-checks-header {
  background-color: var(--secondary-white);
}

.edit-credits-screen .slide-down-animator,
.edit-vehicles-screen .slide-down-animator {
  max-width: 965px !important;
  width: 100%;
  margin: auto;
}

.grid-text-color {
  color: var(--primary-black) !important;
}

.grid-text-error-color {
  color: var(--primary-red) !important;
}

.tax-grid
  .k-grid-content.
  .tax-grid
  .k-grid-content
  > div.
  .tax-grid
  .k-grid-content
  table {
  max-width: 100%;
}

@media (min-width: 768px) {
  .edit-credits-logging-check-ctnr {
    margin-top: 7px !important;
  }
}

.grid-ref {
  /*max-width: 1000px;*/
  width: 100%;
}

.command-cell {
  text-align: center;
}

td.row-expander {
  padding-right: 0 !important;
  padding-left: var(--gutter) !important;
}

/*@media (max-width: 845px) {*/
/*    td.row-expander {*/
/*        padding-right: var(--gutter) !important;*/
/*    }*/
/*}*/

.detail-row {
  max-width: calc(100% - 100px);
  overflow: hidden;
}

.detail-row-ctnr {
  position: relative;
  left: 0;
  top: 0;
}

.rounded-tax-grid table tr:last-child td:first-child {
  border-bottom-left-radius: var(--border-radius);
}

.rounded-tax-grid table tr:last-child td:last-child {
  border-bottom-right-radius: var(--border-radius);
}

.k-grid-content.k-virtual-content {
  overflow-y: hidden;
}

.k-grid-header {
  padding-right: 0 !important;
}

.vehicle-grid-total {
  font-size: 16px;
  margin-bottom: 0;
  font-weight: normal;
  color: var(--primary-black);
}

.grid-button-spaced {
  margin-left: 8px;
}
