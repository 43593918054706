.form-screen .left-side-col .summary-widget {
    margin-bottom: 0;
}

.form-screen .form-padding {
    padding: 15px;
    background-color: white;
    font-size: 1.2em;
}

.form-screen .form-padding td {
    font-size: medium;
}

.form-widget-header {
    padding: 5px 0 7px 0;
    border-bottom: 1px solid var(--primary-green);
    font-size: 1.2em;
    font-weight: 500;
    background-color: var(--soft-green);
}

.form-screen .left-side-col .rounded-tax-grid table tr:last-child td:first-child {
    border-bottom-left-radius: 0;
}

.form-screen .left-side-col .rounded-tax-grid table tr:last-child td:last-child {
    border-bottom-right-radius: 0;
}

.row.vertical-divider {
    overflow: hidden;
}

.row.vertical-divider > div[class^="col-"] {
    border-left: 20px solid white;
    border-right: 20px solid white;
}

.row.vertical-divider div[class^="col-"]:first-child {
    border-left: none;
}

.row.vertical-divider div[class^="col-"]:last-child {
    border-right: none;
}