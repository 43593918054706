/*#wrapper{*/
/*    height:100%;*/
/*}*/

.iconCol {
    background-color: var(--tertiary-green);
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.formCol {
    /*display: flex;*/
    /*justify-content: center;*/
    /*align-items: center;*/
    /*vertical-align: top;*/
    /*height: 100vh !important;*/
    min-height: 100vh;
    padding-right: 30px !important;
}

.form-scroller {
    height: 100vh;
    overflow: auto;
    padding-top: 75px;
    padding-bottom: var(--gutter);
    display: flex;
    justify-content: center;
    /*align-items: center;*/
}


.smallLogoContainer{
    margin-bottom:30px;
    text-align:center;
}

.smallLogoContainer img {
    margin-bottom: var(--gutter);
}


.imgContainer{
    width:250px;
    height:250px;
    /*margin:50% auto;*/
    /*margin-top:280px;*/
}

.formContainer{
    max-width:400px;
    /*margin:50% auto;*/
    /*margin-top:20%;*/
}

.imgContainer img{
    width:100%;
}

/*input[type=text] {*/
    /*background: transparent;*/
    /*border: none;*/
    /*border-radius:0px;*/
    /*border-bottom: 2px solid #c0c0c0;*/
/*}*/

/*input[type=password] {*/
    /*background: transparent;*/
    /*border: none;*/
    /*border-radius:0px;*/
    /*border-bottom: 2px solid #c0c0c0;*/
/*}*/

.info{
    color:#7C7C7C;

}

.label{
    color:#777777;
}

.info a{
    color:var(--primary-black);
}

.passwordLinkCtn{
    text-align:right;
}


/*.inputContainer{*/
/*    margin-bottom: var(--gutter);*/
/*}*/

#signUpBtn{
    background-color:black;
    color:white;
    width:250px;
    border-radius:25px;
    padding-top:10px;
    padding-bottom:10px;
}

.welcomeText{
    color:var(--primary-black);
    text-align:center;
    font-size:1.5rem;
    margin-bottom: 0;
}

#signIn{
    padding:10px 60px;
    background-color:#333333;
    color:white;
    border-radius:4px;
}

#signUp{
    padding:10px 60px;
    background-color:#FFFFFF;
    border-radius:4px;
}

input[type='checkbox'] {
    -webkit-appearance:none;
    width:20px;
    height:20px;
    background:white;
    border-radius:5px;
    border:1px solid #CCC;
    margin-right:10px;
    position:absolute;
    bottom:2px;
}

input[type='checkbox']:checked {
    background: black;
}



#signUpBtnCtn{
    margin-top:50px;
}

#rememberMe{
    margin-left:30px;
}

#footerLinks{
    /*position:absolute;*/
    /*bottom:20px;*/
    text-align:center;
    /*right:0px;*/
    /*left:0px;*/
}

#footerLinks span {
    font-size:10pt;
    margin-left: 5px;
    margin-right: 5px;
}

#footerLinks a {
    font-size:10pt;
    color:#2E0854;
    margin-left:3px;
    margin-right:3px;
}

@media only screen and (max-width: 768px){
    .iconCol{
        display:none;
    }

    body{
        background-color:black;
    }

    .formCol{
        background-color:#EEEEEE;
        padding-right: 15px !important;
    }

    #signIn{
        width:100%;
        padding:15px 5px;
        background-color:#333333;
        color:white;
        border-radius:4px;
        font-size:14px;
    }

    #signUp{
        width:100%;
        padding:15px 5px;
        background-color:#FFFFFF;
        border-radius:4px;
        font-size:14px;
    }

    .welcomeText{
        font-size:14px;
    }

    .formContainer{
        padding-left:var(--gutter);
        padding-right:var(--gutter);
    }
}
