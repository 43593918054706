.order-summary-panel {
    /*display: flex;*/
    margin-bottom: 15px;
    /*padding-left: 15px;*/
    /*padding-right: 15px;*/
    background-color: white;
}

.order-summary-panel section {
    height: 100%;
}

.order-summary-panel .section-1 {
    background-color: #323332;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.order-summary-panel .id-section {
    padding: 15px;
}

.order-summary-panel .section-3 {
    padding-top: 15px;
}

.order-summary-panel .summary-section {
    margin-top: 15px;
    margin-bottom: 15px;
}

.order-summary-panel button {
    max-width: 280px;
}

@media (max-width: 768px) {
    .order-summary-panel button {
        max-width: 100px;
    }
}

.order-summary-header-text, h2.order-summary-header-text {
    font-size: 25px;
}

.order-summary-header-text strong, h2.order-summary-header-text {
    font-weight: 700;
}

.order-summary-header {
    min-height: 88px;
}

.order-summary-header h3 {
    font-size: 1rem;
    font-weight: normal;
    margin-bottom: 0;
}

.ord-summ-h2 {
    font-size: 2rem;
}

.ord-summ-h3 {
    fomr-size: 1.5rem;
}